import axios from "axios";
import { API_ENDPOINT, HEADER_CONFIG } from "../config";
import { TemplateItem } from "../models/TemplateItem";
import { IMalaApiResponse } from '../interfaces/API/IMalaApiResponse';
import { ITemplateSearchParams } from '../interfaces/API/ITemplateSearchParams';
import { IUserTemplate } from "../interfaces/API/IUserTemplate";

const API_NAME = "Template/template/list";
export class TemplateService {
  public getMockGlobalTemplates = (): TemplateItem[] => {
    return [
      { key: "1", name: '01-លិខិតសុំច្បាប់ - 1 to 5 days', groupName: 'MPTC' },
      { key: "2", name: 'លិខិតសុំច្បាប់_more_than_6days', groupName: 'MPTC' },
      { key: "3", name: 'លិខិតអញ្ជើញ', groupName: 'MPTC' },
      { key: "4", name: 'ផ្នែកស្រោមសំបុត្រ', groupName: 'Sereyvath Test Group' }
    ] as TemplateItem[]
  }
  
  getMockOrgTemplates = (): TemplateItem[] => {
    return [
      { key: "1", name: 'SkaiLab NDA for Clients', groupName: 'Contact Agreement' },
      { key: "2", name: 'លិខិតស្នើសុំ', groupName: 'ច្បាប់' },
      { key: "3", name: 'លិខិតធានាអះអាងដើម្បីប្រើប្រាស់ សេវាថែទាំសុខភាពបសស', groupName: 'បសស' },
    ] as TemplateItem[]
  }

  // public getGlobalTemplates = async (): Promise<IMalaApiResponse> => {
  //   // HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
  //   try {
  //     const response = await axios
  //       .get(API_ENDPOINT + `${API_NAME}/global`, HEADER_CONFIG);
  //     return response.data;
  //   } catch (error) {
  //     return error;
  //   }
  // };

  // public getOrgTemplates = async (): Promise<IMalaApiResponse> => {
  //   // HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
  //   try {
  //     const response = await axios
  //       .get(API_ENDPOINT + `${API_NAME}/org`, HEADER_CONFIG);
  //     return response.data;
  //   } catch (error) {
  //     return error;
  //   }
  // };

  public searchTemplates = async (accessToken: string, body: ITemplateSearchParams): Promise<IMalaApiResponse> => {
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .post(API_ENDPOINT + `${API_NAME}`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public postFavoriteTemplate = async (accessToken: string, body: IUserTemplate): Promise<IMalaApiResponse> => {;
    HEADER_CONFIG.headers.Authorization = `Bearer ${accessToken}`;
    
    try {
      const response = await axios
        .post(API_ENDPOINT + `Template/template/PostFavoriteTemplate`, body, HEADER_CONFIG);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}