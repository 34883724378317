import * as React from 'react';
import { createTheme, Pivot, PivotItem } from '@fluentui/react';
import TemplateList from './TemplateList';
import { ListTypes, TabHeaderKeyType } from '../models/Enums';

export interface GetDataPageBodyProps {
    accessToken: string
}

export interface GetDataPageBodyState {
    listType?: number;
}

const theme = createTheme({
    // You can also modify certain other properties such as fontWeight if desired
    defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

export default class GetDataPageBody extends React.Component<GetDataPageBodyProps, GetDataPageBodyState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            listType: ListTypes.FAVORITE,
        };
    }

    onChangePivot = (item?: PivotItem) => {
        switch (item.props.headerText) {
            default:
                this.setState({ listType: ListTypes.FAVORITE });
                break;
            case TabHeaderKeyType.ORG_TEMPLATES:
                this.setState({ listType: ListTypes.ORG });
                break;
            case TabHeaderKeyType.GLOBAL_TEMPLATES:
                this.setState({ listType: ListTypes.GLOBAL });
                break;
        }
    }

    render() {
        const { accessToken } = this.props;
        const { listType } = this.state;

        return (
            <Pivot aria-label="Skai Template menu" onLinkClick={this.onChangePivot} styles={{ 
                    root: { 
                        "& .ms-Pivot-icon": {
                            color: theme.palette.themePrimary
                        },
                        
                        "+ div":{
                            height: '100%',
                            "> div":{
                                height: '100%',
                                "> div":{
                                    height: '100%',
                                }
                            }
                        }
                    }
                }}>
                <PivotItem itemIcon="HeartFill">
                    {
                        listType === ListTypes.FAVORITE &&
                        <TemplateList accessToken={accessToken} listType={ListTypes.FAVORITE}></TemplateList>
                    }
                </PivotItem>
                <PivotItem headerText="Org Templates" >
                    {
                        listType === ListTypes.ORG &&
                        <TemplateList accessToken={accessToken} listType={ListTypes.ORG}></TemplateList>
                    }
                </PivotItem>
                <PivotItem headerText="Global Templates">
                    {
                        listType === ListTypes.GLOBAL &&
                        <TemplateList accessToken={accessToken} listType={ListTypes.GLOBAL}></TemplateList>
                    }
                </PivotItem>
            </Pivot>
        );
    }
}
