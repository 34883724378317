import * as React from "react";

import { ILabelStyles, IStyleSet, Label, Pivot, PivotItem } from '@fluentui/react';

export interface HeaderProps {
  title: string;
  logo: string;
  message: string;
}

export default class Header extends React.Component<HeaderProps> {
  render() {
    const { title, logo, message } = this.props;

    const subText: string = "Find and load template quickly";

    return (
      <section className='sk-template__header'>
        <img width='80' height='80' src={logo} alt={title} title={title} />
        <h1 className='ms-fontSize-xl ms-fontWeight-light ms-fontColor-neutralPrimary'>{message}</h1>        
      </section>
    );
  }
}
