import * as React from 'react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { IconButton, IPersonaSharedProps, IStackTokens, nullRender, Persona, PersonaSize, Stack } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';

export interface MenuProps {
  userDisplayName: string;
  logout: () => {};
}

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 0,
  padding: 7,
};

export const Menu: React.FunctionComponent<MenuProps> = (props) => {
  const { userDisplayName, logout } = props;

  const menuProps = useConst<IContextualMenuProps>(() => ({
    isBeakVisible: false,
    shouldFocusOnMount: true,
    items: [
      { key: 'signout', text: 'Sign Out', iconProps: { iconName: 'SignOut' }, onClick: () => {logout()} },
    ],
  }));

  return (
    <Stack horizontal={true} horizontalAlign='space-between' tokens={itemAlignmentsStackTokens}>
      <Stack.Item tokens={{ margin: '0px 0px 0px 5px' }} align='center'>        
        <Persona
          imageUrl=''
          text={userDisplayName}
          size={PersonaSize.size24}
          hidePersonaDetails={false}
          imageAlt={userDisplayName}
        />
      </Stack.Item>
      <Stack.Item>
        {/* <IconButton iconProps={{ iconName: 'GlobalNavButton' }} title="Menu" ariaLabel="Menu" menuProps={menuProps} onRenderMenuIcon={nullRender} /> */}
      </Stack.Item>
    </Stack>
  );
};