import * as React from "react";

import { DefaultPalette, Link, Spinner, SpinnerSize, Text } from "@fluentui/react";
import jwtDecode from "jwt-decode";
import Progress from "./shared/Progress";
import { logoutFromO365, signInO365, signInSilentO365 } from "../../../utilities/office-apis-helpers";
import { Menu } from './Menu';
import StartPageBody from "./StartPageBody";
import GetDataPageBody from "./GetDataPageBody";
import ErrorPageBody from "./ErrorPageBody";
import _ from "lodash";
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { FONT_LINK } from "../config";

/* global Word, require */
export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  headerMessage?: string;
  errorMessage?: string;
  authStatus?: string;
  userDisplayName?: string;
}

const stackStyles: IStackStyles = {
  root: {
    height: '100%'
  },
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    flexGrow: 0,
    overflow: 'hidden',
  },
};

const nonShrinkingStackItemStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexGrow: 1,
    ">div":{
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  },
};

export default class App extends React.Component<AppProps, AppState> {
  constructor(props, context) {
    super(props, context);
    this.state = {
      authStatus: 'initializing',
      headerMessage: 'Welcome to Skai Template',
      errorMessage: ''
    };

    this.boundSetState = this.setState.bind(this);
    this.setToken = this.setToken.bind(this);
    // this.getToken = this.getToken.bind(this);
    this.displayError = this.displayError.bind(this);
    this.login = this.login.bind(this);
  }

  accessToken: string;

  boundSetState: () => {};

  // getToken = () => {
  //   const cachedTokens = localStorage.getItem("cachedTokens");
  //   if (cachedTokens) {
  //     this.accessToken = JSON.parse(cachedTokens);

  //     let userToken = jwtDecode(this.accessToken) as any;
  //     this.setState({ userDisplayName: userToken.name })

  //     this.setState({ authStatus: 'loggedIn' });
  //   } else {
  //     localStorage.setItem("loggedIn", "");
  //     this.setState({ authStatus: 'notLoggedIn'});
  //   }
  // }

  setToken = (accessToken: string) => {
    this.accessToken = accessToken;

    let userToken = jwtDecode(this.accessToken) as any;

    localStorage.setItem("domainHint", userToken.tid);
    localStorage.setItem("loginHint", userToken.upn);
    
    this.setState({ userDisplayName: userToken.name });
  }

  displayError = (error: string) => {
    this.setState({ errorMessage: error });
  }

  // Runs when the user clicks the X to close the message bar where
  // the error appears.
  errorDismissed = () => {
    this.setState({ errorMessage: '' });

    // If the error occured during a "in process" phase (logging in),
    // the action didn't complete, so return the UI to the preceding state/view.
    this.setState((prevState) => {
      if (prevState.authStatus === 'loginInProcess') {
        return { authStatus: 'notLoggedIn' };
      }
      return null;
    });
  }

  login = async () => {
    await signInO365(this.boundSetState, this.setToken, this.displayError);
  }

  logout = async () => {
    await logoutFromO365(this.boundSetState, this.displayError);
  }

  async componentDidMount() {
    this.authenticateUser();
  }

  async authenticateUser() {
    try {
      let userTokenEncoded = await OfficeRuntime.auth.getAccessToken({
        allowConsentPrompt: true,
        allowSignInPrompt: true,
        forMSGraphAccess: true
      });

      let userToken = jwtDecode(userTokenEncoded) as any; // Using the https://www.npmjs.com/package/jwt-decode library.
      console.log(userToken);

      // let account: AccountInfo = {
      //   homeAccountId: userToken.oid + '.' + userToken.tid,
      //   environment: 'login.windows.net',
      //   tenantId: userToken.tid,
      //   username: userToken.upn,
      //   localAccountId: userToken.oid
      // }

    localStorage.setItem("loginHint", userToken.upn);
    localStorage.setItem("domainHint", userToken.tid);

      //Get token to call API
      await signInSilentO365(this.boundSetState, this.setToken, this.displayError);
    }
    catch (exception) {
      console.log(exception);
      if (exception.code === 13003) {
        // SSO is not supported for domain user accounts, only
        // Microsoft 365 Education or work account.
        this.displayError("Please sign in with Microsoft 365 Education or work account");
      } else {
        // Handle error
        this.login();
      }
    }
  }

  private renderBody = () => {
    const { title, isOfficeInitialized } = this.props;
    
    if (!isOfficeInitialized) {
      return (
        <Progress
          message="Please sideload your addin to see app body."
        />
      );
    }

    if(this.state.errorMessage){
      return (<ErrorPageBody message={this.state.errorMessage} setState={this.boundSetState} />);
    }

    // Set the body of the page based on where the user is in the workflow.
    let body;

    if (this.state.authStatus === 'initializing') {
      body = (<Progress message="Initializing" />);
    }else if (this.state.authStatus === 'notLoggedIn'){
      body = (<StartPageBody title={title} login={this.login} />);
    }else if (this.state.authStatus === 'loginInProcess') {
      body = (<Spinner className='spinner' size={SpinnerSize.large} label='Please sign-in on the pop-up window.' />);
    }else {
      body = (
        <Stack styles={stackStyles}>
          <Stack.Item disableShrink styles={stackItemStyles} >
            <Menu userDisplayName={this.state.userDisplayName} logout={this.logout} />
          </Stack.Item>
          <Stack.Item grow styles={nonShrinkingStackItemStyles}> 
            <GetDataPageBody accessToken={this.accessToken} />
          </Stack.Item>
          <Stack.Item disableShrink styles={stackItemStyles} tokens={{ padding: 15 }}>
            <Text>
              Missing fonts? <Link href={FONT_LINK}>download now!</Link>
            </Text>
          </Stack.Item>
        </Stack>
      );
    }

    return body;
  }

  render() {
    return (
      <>
        {this.renderBody()}
      </>
    );
  }
}