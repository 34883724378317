import * as React from "react";
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, hiddenContentStyle, mergeStyles, PrimaryButton, Toggle } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};

const dialogContentProps = {
  type: DialogType.normal,
  title: 'File cannot be read',
  closeButtonAriaLabel: 'Close',
  subText: 'Template may be broken or inaccessible',
};

interface IDialogProps {
  isHidden: boolean;
  title: string;
  subText: string;
};

export const MessageDialog: React.FunctionComponent<IDialogProps> = (props) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(props.isHidden);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const contentProps = { ...dialogContentProps, ...props }

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: dragOptions,
    }),
    [labelId, subTextId],
  );

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={toggleHideDialog}
      dialogContentProps={contentProps}
      modalProps={modalProps}
    >
      <DialogFooter>
        <DefaultButton onClick={toggleHideDialog} text="Close" />
      </DialogFooter>
    </Dialog>
  );
};