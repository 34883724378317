import * as React from "react";
import { getTheme, Spinner, SpinnerSize } from "@fluentui/react";
import { Stack } from '@fluentui/react/lib/Stack';

export interface LoadingProps {
  message: string;
}

const theme = getTheme();

export default class Loading extends React.Component<LoadingProps> {
  render() {
    const { message } = this.props;

    return (
      <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 99,
        backgroundColor: 'rgb(239, 246, 252, 0.7)'
      }}}>
        <Stack.Item>
          <Spinner size={SpinnerSize.large} label={message} />
        </Stack.Item>
      </Stack>
    );
  }
}
