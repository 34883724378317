export const API_ENDPOINT = "https://api-skaitemplate.dgc-dev.com/api/";
// export const API_ENDPOINT = "https://localhost:44379/api/";

// export const APP_ID = 'fb623854-2a69-482f-bf74-f129784e4e25';
// export const APP_URL = 'https://skaitemplateweb.skailab.asia/';

// Local
export const APP_ID = '8cb6669f-2435-41c6-8c8e-a84ef81ea61b';
export const APP_URL = 'https://localhost:3000/';

export const API_SCOPES = ['api://f38f9215-825a-41b0-858d-1811eef62007/ReadWrite.All'];

export const FONT_LINK = "https://sltemplatev2.z23.web.core.windows.net/download";

export const HEADER_CONFIG = {
  headers: {
    "Content-Type": "application/json",
    Authorization: ""
  }
};