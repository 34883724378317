import * as React from "react";

import {
    DetailsList,
    IColumn,
    IDetailsList,
    IGroup,
    SearchBox,
    SelectionMode,
    ThemeProvider,
    createTheme,
    IDetailsRowStyles, IDetailsListProps,
    DetailsRow,
    IDetailsGroupRenderProps,
    GroupHeader,
    IGroupHeaderStyles,
    IconButton,
    IIconProps,
    ActionButton,
    Text,
    IButtonStyles
} from "@fluentui/react";
import { IStackItemStyles, IStackStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TemplateItem } from "../models/TemplateItem";
import { urlToBase64 } from "../helpers/fileHelper";
import { IMalaApiResponse } from "../interfaces/API/IMalaApiResponse";
import { generateGroups } from "../helpers/listHelper";
import { TemplateService } from "../services/TemplateService";
import Progress from "./shared/Progress";
import Loading from "./shared/Loading";
import { ITemplateSearchParams } from '../interfaces/API/ITemplateSearchParams';
import { TemplateSearchParams } from "../models/TemplateSearchParams";
import { MessageDialog } from "./shared/MessageDialog";
import { IUserTemplate } from '../interfaces/API/IUserTemplate';
import { ManifestDialog } from "./ManifestDialog";
import { ITemplateManifest } from "../interfaces/API/ITemplateManifest";
import { Dictionary } from "lodash";

const theme = createTheme({
    // You can also modify certain other properties such as fontWeight if desired
    defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

const stackTokens: Partial<IStackTokens> = { childrenGap: 5 };

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        flexGrow: 0,
        overflow: 'hidden',
        // backgroundColor: "yellow"
    },
};

const listStackItemStyles: IStackStyles = {
    root: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
        // backgroundColor: "red",
        height: 1,
        '>div':{
            width: '100%'
        }
    },
};

export interface ITemplatesState {
    item: TemplateItem;
    items: TemplateItem[];
    groups: IGroup[];
    loading: boolean;
    opening: boolean;
    showMessageDialog: boolean;
    showManifestDialog: boolean;
    manifests: ITemplateManifest[];

    isNewWindow: boolean;
}

export interface ITemplatesProps {
    accessToken: string;
    listType: number;
}

const heartIcon: IIconProps = { iconName: 'Heart' };
const heartFillIcon: IIconProps = { iconName: 'HeartFill' };

export default class TemplateList extends React.Component<ITemplatesProps, ITemplatesState> {
    private _root = React.createRef<IDetailsList>();
    private _columns: IColumn[];

    constructor(props: ITemplatesProps) {
        super(props);
        this.state = {
            items: [],
            groups: [],
            manifests: [],

            loading: false,
            opening: false,
            showMessageDialog: false,
            showManifestDialog: false,

            item: null,
            isNewWindow: false
        };

        this._columns = [
            { key: 'name', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true }
        ];
    }

    componentDidMount(): void {
        this.handleSearch("");
    }

    handleSearch(term: string): void {
        const { listType } = this.props;

        this.setState({ loading: true });

        let templateService = new TemplateService();
        let templates: TemplateItem[];
        let groups: IGroup[];
        let params: ITemplateSearchParams = new TemplateSearchParams();

        params.searchTerm = term;
        params.listType = listType;

        switch (Office.context.host) {
            case Office.HostType.Excel:
                params.fileType = '.xlsx';
                break;
            case Office.HostType.Word:
                params.fileType = '.docx';
                break;
            case Office.HostType.PowerPoint:
                params.fileType = '.pptx';
                break;
            default:
                throw "Unsupported Office host application: This add-in only runs on Excel, PowerPoint, or Word.";
        }

        templateService.searchTemplates(this.props.accessToken, params).then((data: IMalaApiResponse) => {

            templates = data.data as TemplateItem[];
            groups = generateGroups(templates);

            this.setState({ items: templates, groups: groups, loading: false });
        });
    }

    onSearchTemplate = (newValue: any): void => {
        // Office.context.ui.messageParent("You have searched for " + newValue);
        console.log("You have searched for " + newValue);
        this.handleSearch(newValue + "");
    }

    onClearSearchTemplate = (_ev?: any): void => {
        //reset search
        this.handleSearch("");
    }

    onChangeSearchTemplate = (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
        if (newValue === "") {
            //reset search
            this.handleSearch("");
        }
    }

    onClickOpenDialog = () => {
        this.setState({ showManifestDialog: !(this.state.showManifestDialog) });
    }

    onSubmitManifest = (manifestItems: any) => {
        ;
        // console.log(manifestItems);
        this.renderDocument(this.state.item, manifestItems, this.state.isNewWindow);

        this.setState({ showManifestDialog: !(this.state.showManifestDialog) });
    }

    render() {
        const { children } = this.props;
        const { items, groups } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <Stack tokens={stackTokens} styles={{ root: { height: '100%' } }}>
                    <Stack.Item className="section-search" styles={stackItemStyles}>
                        <SearchBox styles={{ root: { width: '100%' } }} placeholder="Search templates" onSearch={this.onSearchTemplate} onClear={this.onClearSearchTemplate} />
                    </Stack.Item>
                    {
                        this.state.opening &&
                        <Loading message="Please wait..." />
                    }
                    {
                        this.state.loading &&
                        <Progress message="Loading templates" />
                    }
                    {
                        !this.state.loading &&
                        <React.Fragment>
                            {
                                this.state.items.length > 0 ?
                                    <Stack.Item className="section-templates" styles={listStackItemStyles}>
                                        <DetailsList
                                            componentRef={this._root}
                                            items={items}
                                            groups={groups}
                                            columns={this._columns}
                                            groupProps={{
                                                showEmptyGroups: true,
                                                onRenderHeader: this._onRenderGroupHeader
                                            }}
                                            onRenderItemColumn={(item, index, column) => this._onRenderItemColumn(item, index, column, this.onClick)}
                                            onRenderRow={this._onRenderRow}
                                            compact={true}
                                            isHeaderVisible={false}
                                            selectionMode={SelectionMode.none}
                                        />                                        
                                        {children}
                                    </Stack.Item>
                                    :
                                    <Stack.Item align="center">
                                        <Text>
                                            No result found 🤷‍♀️
                                        </Text>
                                    </Stack.Item>
                            }
                        </React.Fragment>
                    }
                    {/* <Stack.Item className="section-footer">
                    <ThemeProvider theme={theme}>
                        <p>
                            Missing fonts? visit our{' '}
                            <Link href={FONT_LINK} underline>
                            portal
                            </Link>
                            .
                        </p>
                    </ThemeProvider>
                </Stack.Item> */}
                    <MessageDialog title="Cannot open template" subText="The template is broken or inaccessible" isHidden={!this.state.showMessageDialog}></MessageDialog>
                    <ManifestDialog hideDialog={!(this.state.showManifestDialog)} toggleHideDialog={this.onClickOpenDialog} submitManifest={this.onSubmitManifest} manifests={this.state.manifests} />
                </Stack>

            </ThemeProvider>
        );
    }

    private addToFavorite = async (templateItem: TemplateItem, index: number) => {
        let templateService = new TemplateService();
        let params: IUserTemplate = {
            templateId: templateItem.id,
            isFavorite: !(templateItem.isFavorite)
        };

        await templateService.postFavoriteTemplate(this.props.accessToken, params).then(() => {
            templateItem.isFavorite = !(templateItem.isFavorite);

            let newItems = this.state.items;
            newItems[index] = templateItem;

            this.setState({
                items: [...newItems]
            });
        })
    }

    private onClick = async (templateItem: TemplateItem, index: number, toggleFavorite: boolean = false, isNewWindow: boolean = false) => {
        this.setState({ item: templateItem, isNewWindow: isNewWindow });
        // console.log(item);

        // let docUrl = "https://sktemplatestorage.blob.core.windows.net/public-blobs/Sample.docx";

        if (toggleFavorite) {
            this.addToFavorite(templateItem, index);
            return;
        }

        if (templateItem.manifests.length > 0) {
            this.setState({ manifests: templateItem.manifests, showManifestDialog: true });
        } else {
            this.renderDocument(templateItem, null, isNewWindow);
        }
    };

    private renderDocument = (templateItem: TemplateItem, manifestItems: any, isNewWindow: boolean = false) => {
        this.setState({ opening: true });
        try {
            urlToBase64(templateItem.url, (base64file: string) => {
                this.setState({ opening: false });

                return Word.run(async (context) => {

                    if (isNewWindow) {
                        let newDoc = context.application.createDocument(base64file);
                        let docBody = newDoc.body;

                        if (manifestItems) {
                            for (let mi of templateItem.manifests) {
                                var results = docBody.search(mi.textToReplace);
                                context.load(results);

                                await context.sync().then(() => {
                                    for (var i = 0; i < results.items.length; i++) {
                                        if (manifestItems[mi.name]) {
                                            results.items[i].insertText(manifestItems[mi.name], Word.InsertLocation.replace);
                                        }
                                    }
                                });
                            }

                            //for replace in textbox, word art etc
                            const ooxml = docBody.getOoxml();
                            await context.sync().then(async () => {
                                const parser = new DOMParser();
                                const xmlDoc = parser.parseFromString(ooxml.value, "text/xml");

                                const serializedXML = this.replaceTextInObject(xmlDoc, templateItem, manifestItems);
                                docBody.clear();

                                await context.sync().then(() => {
                                    docBody.insertOoxml(serializedXML, Word.InsertLocation.replace);
                                })
                            });
                        }

                        newDoc.open();
                    } else {
                        // let doc = context.application.createDocument(base64file);
                        context.document.body.insertFileFromBase64(base64file, Word.InsertLocation.end);
                        let docBody = context.document.body;

                        if (manifestItems) {
                            for (let mi of templateItem.manifests) {
                                var results = docBody.search(mi.textToReplace);
                                context.load(results);

                                await context.sync().then(() => {
                                    for (var i = 0; i < results.items.length; i++) {
                                        if (manifestItems[mi.name]) {
                                            results.items[i].insertText(manifestItems[mi.name], Word.InsertLocation.replace);
                                        }
                                    }
                                });
                            }

                            //for replace in textbox, word art etc
                            const ooxml = docBody.getOoxml();
                            await context.sync().then(async () => {
                                const parser = new DOMParser();
                                const xmlDoc = parser.parseFromString(ooxml.value, "text/xml");

                                const serializedXML = this.replaceTextInObject(xmlDoc, templateItem, manifestItems);
                                docBody.clear();

                                await context.sync().then(() => {
                                    docBody.insertOoxml(serializedXML, Word.InsertLocation.replace);
                                })
                            });
                        }
                    }

                    await context.sync();
                });
            });
        } catch (error) {
            console.log(error);
            this.setState({ showMessageDialog: true });
        } finally {
            this.setState({ opening: false });
        }
    }

    private replaceTextInObject = (xmlDoc: Document, templateItem: TemplateItem, manifestItems: any) => {
        const rows = xmlDoc.getElementsByTagName("w:r");
        for (let j = 0; j < rows.length; j++) {
            const row = rows[j];
            const rowHasTextBox = row.getElementsByTagName("wps:txbx").length > 0;
            //If no textbox, shape, wordart exists skip current run
            if (!rowHasTextBox) {
                continue;
            }

            //Select textbox, shape, wordart and get paragraphs
            const textboxContainer = row.getElementsByTagName("wps:txbx")[0];
            const paragraphs = textboxContainer.getElementsByTagName("w:p");

            //Loop through paragraphs
            for (let p = 0; p < paragraphs.length; p++) {
                //Check whether paragraph has text
                const paragraphHasText = paragraphs[p].getElementsByTagName("w:t").length > 0;
                if (!paragraphHasText) continue;

                const textBoxTexts = paragraphs[p].getElementsByTagName("w:t");
                for (let k = 0; k < textBoxTexts.length; k++) {
                    const textBoxText = textBoxTexts[k].innerHTML;

                    for (let m of templateItem.manifests) {
                        if (manifestItems[m.name]) {
                            if (textBoxText.indexOf(m.textToReplace) > -1) {
                                let replacedText = textBoxText.replace(m.textToReplace, manifestItems[m.name]);
                                textBoxTexts[k].innerHTML = replacedText;
                            }
                        }
                    }
                }
            }
        }

        const serializedXML = new XMLSerializer().serializeToString(xmlDoc.documentElement);
        return serializedXML;
    }

    private _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
        const customStyles: Partial<IGroupHeaderStyles> = {};
        if (props) {
            customStyles.root = {
                color: theme.palette.blue,
                '&:hover': {
                    color: theme.palette.blueMid
                }
            };
            return (
                <GroupHeader {...props} styles={customStyles} />
            );
        }

        return null;
    };

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                //customStyles.root = { backgroundColor: theme.palette.themeLighterAlt, borderBottom: `1px solid ${theme.palette.themeLight}`};
                //customStyles.root = { borderBottom: `1px solid ${theme.palette.themeLighterAlt}` };
            }

            customStyles.root = {
                // '&>span': {
                //     width: '5px !important',
                // },
                '& .ms-DetailsRow-cell': {
                    // whiteSpace: 'normal',
                    // cursor: 'pointer'
                    position: 'initial'
                }
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    private _onRenderItemColumn(item: TemplateItem, index: number, column: IColumn, clickEv: any) {
        const value = (item && column && column.fieldName) ? item[column.fieldName as keyof TemplateItem] || '' : '';
        const backIcon: IIconProps = { iconName: 'ReplyAlt' };

        const loadButtonStyles: Partial<IButtonStyles> = {};
        const openButtonStyles: Partial<IButtonStyles> = {};

        loadButtonStyles.root = {
            position: 'absolute',
            left: '3px'
        };

        openButtonStyles.root = {
            fontSize: '90%',
            color: theme.palette.blue
        };

        switch (column.key) {
            case "name": return <Stack key={index} horizontal verticalAlign='center' styles={{ root: { position: 'inherit' } }}>
                <IconButton styles={loadButtonStyles}
                    onClick={() => clickEv(item, index)}
                    iconProps={backIcon}
                    title="Load in this window"
                    ariaLabel="Load"
                    data-is-focusable={false} />
                <Text styles={{ root: { whiteSpace: 'normal', fontSize: 'inherit', flexGrow: 1 } }}>
                    {value}
                    <span title="Owned by your organization">{item.isCustomShared ? " *" : ""}</span>
                </Text>
                <IconButton onClick={() => clickEv(item, index, true)} iconProps={item.isFavorite ? heartFillIcon : heartIcon} title="Toggle favorite" aria-label="Toggle favorite" />
                <ActionButton
                    onClick={() => clickEv(item, index, false, true)}
                    styles={openButtonStyles}
                    text="Open"
                    title="Open in new window" ariaLabel="Open" />
            </Stack>;
        }

        return <div key={index} data-is-focusable={true}>{value}</div>;
    }
}
