import { IGroup } from "@fluentui/react";
import _ from "lodash";
import { ITemplateItem } from "../interfaces/API/ITemplateItem";

export const generateGroups = (items: ITemplateItem[]) => {
  let groups: IGroup[] = [];

  const itemGroups = _.groupBy(items, (item) => {
    return item.groupName;
  });

  let i = 0;
  groups = _.map(itemGroups, (val, key) => {
    let data = { key: "g" + i, name: key, startIndex: _.indexOf(items, val[0]), count: val.length } as IGroup;
    i++;
    return data;
  });

  // console.log("items", items);
  // console.log("groups", groups);

  return groups;
}