import * as React from 'react';
import { ButtonType, DefaultButton } from '@fluentui/react';
import { AppState } from './App';

export interface ErrorPageBodyProps {
    message: string;
    setState: (x: AppState) => void;
}

export default class ErrorPageBody extends React.Component<ErrorPageBodyProps> {
    render() {
        const { message, setState } = this.props;

        return (
            <div className='sk-template__main'>
                <h2 className='ms-font-md ms-fontWeight-light ms-fontColor-neutralPrimary ms-u-slideUpIn20'>Error</h2>
                <p className='ms-font-sm ms-fontWeight-semilight ms-fontColor-neutralPrimary ms-u-slideUpIn20'>{message}</p>
                <DefaultButton className='sk-template__action' buttonType={ButtonType.hero} iconProps={{ iconName: 'ChevronLeft' }} onClick={()=> setState({ errorMessage: '' })}>Back</DefaultButton>
            </div>
        );
    }
}
