import * as React from 'react';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { ITemplateManifest } from '../interfaces/API/ITemplateManifest';
import { createTheme, MessageBar, MessageBarType, TextField, ThemeProvider } from '@fluentui/react';
import _, { Dictionary } from 'lodash';
import { useBoolean } from '@fluentui/react-hooks';

const modelProps = {
  isBlocking: true
};

const theme = createTheme({
  // You can also modify certain other properties such as fontWeight if desired
  defaultFontStyle: { fontFamily: 'sans-serif, Segoe UI, Suwannaphum' }
});

export interface IManifestDialogProps {
  hideDialog: boolean;
  manifests: ITemplateManifest[];
  toggleHideDialog: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  submitManifest: (manifestItems: any) => void;
}

export const ManifestDialog: React.FunctionComponent<IManifestDialogProps> = (props) => {
  const { hideDialog, manifests, toggleHideDialog, submitManifest } = props;
  const [manifestItems, setManifestItems] = React.useState<any>({});

  const [hideErrors, setHideErrors] = React.useState(true);

  const toggleErrors = () => {
    setHideErrors(!hideErrors);
  }

  const onSubmitManifest = () => {
    let isAnyEmpty = false;
    setHideErrors(true);

    _.forEach(manifestItems, (value) => {
      if (!value) {
        isAnyEmpty = true;
      }
    });

    if (Object.keys(manifestItems).length < manifests.length || isAnyEmpty) {
      //alert('Please fill all the fields');
      setHideErrors(false);
      return;
    }
    
    submitManifest(manifestItems);
  }

  const onChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, manifestName: string, newValue?: string) => {
    manifestItems[manifestName] = newValue;
    setManifestItems({
      ...manifestItems
    });
  }

  const renderManifests = () => {
    if (manifests.length > 0) {
      return manifests.map((manifest, index) => {
        return (
          <TextField key={index} value={manifestItems[manifest.name]} label={manifest.name} onChange={(ev, newValue) => onChange(ev, manifest.name, newValue)} required />
        )
      })
    }

    return "No manifests found";
  }

  return (
    <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} modalProps={modelProps}>
      {
        !hideErrors &&
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={toggleErrors}
          dismissButtonAriaLabel="Close"
        >
          Please fill all required fields        
        </MessageBar>
      }
      <div>
        <ThemeProvider theme={theme}>
          {
            renderManifests()
          }
        </ThemeProvider>
      </div>      
      <DialogFooter>
        <PrimaryButton onClick={onSubmitManifest} text="Submit" />
        <DefaultButton onClick={toggleHideDialog} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
